<template>
  <div class="setting publicBox">
    <el-form :model="ruleForm" ref="ruleForm" label-width="170px" class="demo-ruleForm">
      <div class="publicBoxTitle">
        市场信息
      </div>
      <el-form-item label="所属市场：">
        <el-select v-model="params.id" @change="changeMkt" class="public-input" placeholder="请选择所属市场">
          <el-option :label="item.mktName" :value="item.id" v-for="item in bazaarList" :key="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="市场编号：">
        <el-input class="public-input" :disabled="true" v-model="params.mktId"></el-input>
      </el-form-item>
      <el-form-item label="主体银行账户名称：" prop="enterpriseMerchantId">
        <el-select v-model="ruleForm.enterpriseMerchantId" multiple class="public-input" placeholder="请选择主体银行账户名称">
          <el-option :label="item.mktMrchNm" :value="item.id" v-for="item in merchantList" :key="item.id"></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="账户证件号：">
        <el-input class="public-input" :disabled="true" v-model="params.crdtNo"></el-input>
      </el-form-item>
      <el-form-item label="账户商家编号：">
        <el-input class="public-input" :disabled="true" v-model="params.mktMrchId"></el-input>
      </el-form-item> -->
      <el-form-item label="消息接收手机号：" prop="changePhone">
        <el-input class="public-input" placeholder="请输入消息接收手机号" v-model="ruleForm.changePhone"></el-input>
      </el-form-item>
      <div class="publicBoxTitle">
        手续费
      </div>
      <el-form-item label="是否收取手续费" prop="charge">
        <el-radio-group v-model="ruleForm.charge" @change="changeCharge">
          <el-radio :label="0">不收取</el-radio>
          <el-radio :label="1">收取</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="收取客户商家手续费用：" prop="chargeType" v-if="ruleForm.charge == 1">
        <el-select v-model="ruleForm.chargeType" class="public-input" placeholder="请选择">
          <el-option label="单笔固定" :value="0"></el-option>
          <el-option label="百分比" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="ruleForm.chargeType == 1 ? '百分比率：' : '单笔固定：'" prop="chargeType" v-if="ruleForm.chargeType !== ''">
        <el-input class="public-input" :placeholder="ruleForm.chargeType == 1 ? '请输入百分比率': '请输入固定费用'" v-model="ruleForm.chargeValue">
          <template slot="append">{{ruleForm.chargeType == 1 ? '%' : '元'}}</template>
        </el-input>
      </el-form-item>
      <div class="publicBoxTitle">
        客户收取商家手续费限制
      </div>
      <el-form-item label="单笔固定费用≤：">
        <el-input class="public-input" placeholder="请输入单笔固定费用" v-model="ruleForm.chargeFixedAmount">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="百分比率≤：">
        <el-input class="public-input" placeholder="请输入百分比率" v-model="ruleForm.chargeRate">
          <template slot="append">%</template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <myButton title="保存" @myclick="save"></myButton>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  name: 'setting',
  data() {
    return {
      ruleForm: {
        enterpriseId: '',
        enterpriseMerchantInfo: [],
        changePhone: '',
        charge: '',
        chargeType: '',
        chargeFixedAmount: '',
        chargeRate: '',
        chargeValue: ''
      },
      params: {
        index: '',
        mktId: '',
        id: '',
        crdtNo: '',
        mktMrchId: ''
      },
      bazaarList: [],
      merchantList: []
    };
  },
  created() {
    this.ruleForm.enterpriseId = this.$route.query.id;
    this.getBazaar();
  },
  methods: {
    changeCharge(e) {
      if (e == 0) {
        this.ruleForm.chargeType = '';
        this.ruleForm.chargeValue = '';
      }
    },
    getBazaar() {
      this.$request.HttpGet('/admin/hsbMktInfo/list', {
        page: 1,
        pageSize: 10000
      }).then(res => {
        this.bazaarList = res.data.list;
        this.getDetail();
      });
    },
    changeMkt(id) {
      this.bazaarList.forEach(item => {
        if (item.id == id) {
          this.params.mktId = item.mktId;
        }
      });
      this.getMerchantList();
    },
    changeName(id) {
      this.merchantList.forEach(item => {
        if (item.id == id) {
          this.params.crdtNo = item.crdtNo;
          this.params.mktMrchId = item.mktMrchId;
          this.ruleForm.changePhone = item.mblphNo;
        }
      });
    },
    getMerchantList() {
      this.$request.HttpGet('/admin/hsbMerchant/list', {
        page: 1,
        pageSize: 100000,
        enterprise_merchant_status: 2,
        mktId: this.params.id,
        enterprise_id: this.ruleForm.enterpriseId
      }).then(res => {
        this.merchantList = res.data.list;
        if (this.changeName) {
          // this.changeName(this.ruleForm.hsbMerchantId);
        }
      });
    },
    getDetail() {
      this.$request.HttpGet('/admin/hsbEnterpriseMerchant/find', {
        id: this.ruleForm.enterpriseId
      }).then(res => {
        if (res.data) {
          let enterpriseMerchantId = [];
          res.data.forEach(item => {
            enterpriseMerchantId.push(item.hsbMerchantId)
          });
          this.ruleForm = {
            enterpriseId: this.ruleForm.enterpriseId,
            enterpriseMerchantId: enterpriseMerchantId,
            changePhone: res.data[0].changePhone,
            charge: res.data[0].charge,
            chargeType: res.data[0].chargeType,
            chargeFixedAmount: res.data[0].chargeFixedAmount,
            chargeRate: res.data[0].chargeRate,
            chargeValue: res.data[0].chargeValue
          };
          this.params.id = res.data[0].hsb_merchant ? res.data[0].hsb_merchant.mktId : '';
          if (res.data[0].hsb_merchant) {
            this.changeMkt(res.data[0].hsb_merchant.mktId);
          }
        }
      });
    },
    save() {
      this.$request.HttpPost('/admin/hsbEnterpriseMerchant/create', this.ruleForm).then(res => {
        this.$plugin.notifySuccess(res.msg);
        this.$router.go(-1);
      });
    }
  }
};
</script>
<style lang="scss" scoped>
  .setting {
    padding-bottom: 50px;
  }
</style>
